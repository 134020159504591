import gql from "graphql-tag";
import { MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT } from "./fragments";

export const PRODUCT_QUERY = gql`
  ${MEDIA_FRAGMENT}
  query Product(
    $country: CountryCode
    $language: LanguageCode
    $handle: String!
  ) @inContext(country: $country, language: $language) {
    product(handle: $handle) {
      alignement: metafield(namespace: "image", key: "alignement") {
        value
      }
      description
      id
      images(first: 10) {
        nodes {
          altText
          height
          id
          url
          width
        }
      }
      media(first: 10) {
        nodes {
          ...Media
        }
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      publishedAt
      seo {
        description
        title
      }
      tags
      title
      vendor
      variants(first: 100) {
        nodes {
          id
          availableForSale
          selectedOptions {
            name
            value
          }
          image {
            id
            url
            altText
            width
            height
          }
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
          sku
          title
          unitPrice {
            amount
            currencyCode
          }
        }
      }
    }
    shop {
      shippingPolicy {
        body
        handle
      }
      refundPolicy {
        body
        handle
      }
    }
  }
`;

export const ALL_PRODUCTS_QUERY = gql`
  ${PRODUCT_CARD_FRAGMENT}
  query AllProducts(
    $country: CountryCode
    $language: LanguageCode
    $pageBy: Int!
    $cursor: String
  ) @inContext(country: $country, language: $language) {
    products(
      first: $pageBy
      after: $cursor
      reverse: true
      sortKey: UPDATED_AT
    ) {
      nodes {
        ...ProductCard
        isPartOfCollection: metafield(
          namespace: "featured_product"
          key: "is_part_of_collection"
        ) {
          value
        }
        thumbnailDefault: metafield(namespace: "thumbnail", key: "default") {
          reference {
            ... on MediaImage {
              image {
                altText
                url
              }
            }
          }
        }
        thumbnailHover: metafield(namespace: "thumbnail", key: "hover") {
          reference {
            ... on MediaImage {
              image {
                altText
                url
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const FEATURED_PRODUCT_QUERY = gql`
  query FeaturedProduct($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collectionByHandle(handle: "featured-product") {
      products(first: 1) {
        nodes {
          cta: metafield(namespace: "featured_product", key: "cta") {
            value
          }
          description: metafield(
            namespace: "featured_product"
            key: "description"
          ) {
            value
          }
          handle
          imageDesktop: metafield(
            namespace: "featured_product"
            key: "image_desktop"
          ) {
            reference {
              ... on MediaImage {
                image {
                  altText
                  url
                }
              }
            }
          }
          imageMobile: metafield(
            namespace: "featured_product"
            key: "image_mobile"
          ) {
            reference {
              ... on MediaImage {
                image {
                  altText
                  url
                }
              }
            }
          }
          isPartOfCollection: metafield(
            namespace: "featured_product"
            key: "is_part_of_collection"
          ) {
            value
          }
          title
        }
      }
    }
  }
`;

export const SHOP_CURRENCIES_QUERY = gql`
  query AllShopCurrencies {
    localization {
      availableCountries {
        currency {
          name
          isoCode
          symbol
        }
        availableLanguages {
          name
          isoCode
        }
        name
        isoCode
      }
    }
  }
`;
