import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_number_between_two_values
const getRandomArbitrary = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

const updateCharacterAtIndex = (
  text: string,
  index: number,
  character: string
) => `${text.substring(0, index)}${character}${text.substring(index + 1)}`;

const getCharacters = (characters: string[] = []) => {
  if (characters.length > 0) {
    return characters;
  }

  const array = [];

  for (let i = 0; i < 26; i += 1) {
    if (i < 15) {
      // Characters like #, $ and %
      array.push(String.fromCharCode(33 + i));
    }

    // Characters a-z
    array.push(String.fromCharCode(97 + i));
  }

  return array;
};

const getTimeStampMarkers = (duration: number, maxDuration: number) => {
  const timeStampMarkers: number[] = [];
  let startMarker = duration;

  while (startMarker <= maxDuration) {
    timeStampMarkers.push(startMarker);
    startMarker += duration;
  }

  return timeStampMarkers;
};

const getRandomCharacter = (characters: string[]) =>
  characters[Math.floor(Math.random() * characters.length)];

export interface ScrambleOptions {
  characters?: string[];
  durationRangeEnd?: number;
  durationRangeStart?: number;
  excludedCharacters?: string[];
  letterVisibleDuration?: number;
  paused?: boolean;
  random?: boolean;
  staggerDuration?: number;
}

export const useScramble = (
  originalText: string,
  options: ScrambleOptions = {}
): string => {
  const {
    characters,
    durationRangeEnd = 300,
    durationRangeStart = 200,
    excludedCharacters = [" ", " "],
    letterVisibleDuration = 60,
    paused = false,
    random = false,
    staggerDuration = 100,
  } = options;
  const [scramble, setScramble] = useState(originalText);
  const timeouts = useRef<NodeJS.Timeout[]>([]);

  const memoizedCharactersDeps = JSON.stringify(characters);
  const memoizedExcludedCharacters = JSON.stringify(excludedCharacters);

  const memoizedCharacters = useMemo(
    () => getCharacters(characters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoizedCharactersDeps]
  );

  const decode = useCallback(() => {
    // We don't use the index from the array for staggering because we might skip characters
    // And skipped characters shouldn't delay the animation
    let staggerIndex = 0;
    const randomIndexes = Array.from(Array([...originalText].length).keys());

    [...originalText].forEach((character, index) => {
      // Skip excluded characters
      if (!JSON.parse(memoizedExcludedCharacters).includes(character)) {
        staggerIndex += 1;
        let randomIndex = undefined;

        // When random is enabled we take a random index from the randomIndexes array
        // And remove it so it can't be used twice
        if (random) {
          randomIndex =
            randomIndexes[
              Math.floor(getRandomArbitrary(0, randomIndexes.length))
            ];

          randomIndexes.splice(randomIndexes.indexOf(randomIndex), 1);
        }

        const timeout = setTimeout(() => {
          const totalDuration = getRandomArbitrary(
            durationRangeStart,
            durationRangeEnd
          );

          const timeStampMarkers = getTimeStampMarkers(
            letterVisibleDuration,
            totalDuration
          );

          let startTimeStamp: undefined | number;

          const step = (timeStamp: number) => {
            if (startTimeStamp === undefined) {
              startTimeStamp = timeStamp;
            }

            const elapsed = timeStamp - startTimeStamp;

            if (elapsed >= timeStampMarkers[0]) {
              timeStampMarkers.splice(0, 1);

              startTransition(() => {
                setScramble((prevScramble) =>
                  updateCharacterAtIndex(
                    prevScramble,
                    index,
                    getRandomCharacter(memoizedCharacters)
                  )
                );
              });
            }

            if (elapsed < totalDuration) {
              requestAnimationFrame(step);
            } else {
              startTransition(() => {
                setScramble((prevScramble) =>
                  updateCharacterAtIndex(
                    prevScramble,
                    index,
                    originalText.charAt(index)
                  )
                );
              });
            }
          };

          requestAnimationFrame(step);

          // Remove the timeout id since it's done and doesn't need to be cleared anymore
          timeouts.current.splice(timeouts.current.indexOf(timeout), 1);
        }, staggerDuration * (randomIndex || staggerIndex));

        // Save the timeout id so it can be cleared later if necessary
        timeouts.current.push(timeout);
      }
    });
  }, [
    durationRangeEnd,
    durationRangeStart,
    letterVisibleDuration,
    memoizedCharacters,
    memoizedExcludedCharacters,
    originalText,
    random,
    staggerDuration,
  ]);

  useEffect(() => {
    const scramble = [...originalText].reduce(
      (accumulator, currentValue) =>
        (accumulator += JSON.parse(memoizedExcludedCharacters).includes(
          currentValue
        )
          ? currentValue
          : getRandomCharacter(memoizedCharacters)),
      ""
    );

    startTransition(() => {
      setScramble(scramble);
    });
  }, [memoizedCharacters, memoizedExcludedCharacters, originalText]);

  useEffect(() => {
    if (!paused) {
      decode();
    }
  }, [decode, paused]);

  // In theory text which is unmounted could still be animating
  // This useEffect runs on unmount and clears all the timeouts
  useEffect(
    () => () => {
      timeouts.current.forEach((timeout) => {
        clearTimeout(timeout);
      });
    },
    []
  );

  return scramble;
};
