import { css } from "styled-components";

export const layoutPadding = () => css`
  padding-left: ${({ theme }) => theme.space[8]};
  padding-right: ${({ theme }) => theme.space[8]};

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    padding-left: ${({ theme }) => theme.space[16]};
    padding-right: ${({ theme }) => theme.space[16]};
  }
`;
