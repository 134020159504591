import type { ASTNode } from "graphql";
import { print } from "graphql";
import {
  ALL_PRODUCTS_QUERY,
  FEATURED_PRODUCT_QUERY,
  SHOP_CURRENCIES_QUERY,
  PRODUCT_QUERY,
} from "./queries";
import type {
  Shop,
  Product,
  PageInfo,
} from "@shopify/hydrogen/dist/esnext/storefront-api-types";
import {
  SHOPIFY_STORE,
  SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  SHOPIFY_STOREFRONT_API_VERSION,
} from "~/config";
import fetch from "node-fetch";
import { FeaturedProduct } from "~/@types/shopify";

export interface DEPTApparelProduct extends Product {
  isPartOfCollection: "true" | "false" | null;
}

export interface CurrencyWithLanguage {
  currencyCode: string;
  currencySymbol: string;
  countryName: string;
  countryCode: string;
  i18nCode: string;
}

export async function shopifyQuery(
  query: ASTNode,
  variables: Record<string, any>
) {
  const url = `https://${SHOPIFY_STORE}.myshopify.com/api/${SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      query: print(query),
      variables,
    }),
    headers: {
      "X-Shopify-Storefront-Access-Token": SHOPIFY_STOREFRONT_ACCESS_TOKEN,
      "content-type": "application/json",
    },
  });

  if (response.status !== 200) {
    throw new Error(
      `request failed with status ${response.status} ${response.statusText}`
    );
  }

  const data = await response.json();
  return data.data;
}

export const getProductsByPage = async (
  country: string,
  cursor: string | null = null
): Promise<{
  products: {
    nodes: DEPTApparelProduct[];
    pageInfo: PageInfo;
  };
}> => {
  const allProductsResponse = await shopifyQuery(ALL_PRODUCTS_QUERY, {
    country,
    cursor,
    pageBy: 10,
  });

  return allProductsResponse;
};

export const getProductByHandle = async (
  handle: string,
  country: string
): Promise<{
  product: Product;
  shop: Shop;
}> => {
  return await shopifyQuery(PRODUCT_QUERY, {
    country,
    handle,
  });
};

export const getFeaturedProduct = async (
  country: string
): Promise<FeaturedProduct> => {
  const featuredProductResponse = await shopifyQuery(FEATURED_PRODUCT_QUERY, {
    country,
  });

  return featuredProductResponse?.collectionByHandle?.products?.nodes?.[0];
};

export const getSupportedCurrenciesWithLanguages = async (): Promise<
  CurrencyWithLanguage[]
> => {
  const shopResponse = await shopifyQuery(SHOP_CURRENCIES_QUERY, {});

  const supportedCurrenciesWithLanguages = (
    shopResponse.localization.availableCountries ?? []
  ).map((country: any) => ({
    currencyCode: country.currency.isoCode,
    currencySymbol: country.currency.symbol,
    countryName: country.name,
    countryCode: country.isoCode,
    i18nCode: country.availableLanguages[0].isoCode,
  }));

  return supportedCurrenciesWithLanguages;
};

export async function allProducts(country: string): Promise<any> {
  const allProductsResponse = await shopifyQuery(ALL_PRODUCTS_QUERY, {
    country,
    pageBy: 25,
    cursor: null,
  });

  return allProductsResponse?.products?.nodes;
}
